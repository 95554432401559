import React, { useState } from 'react'

function MobileDropdown(props) {
  const [meunopen, setMeunopen] = useState(false)
  const main_name = props.main
  const link1     = props.link1
  const link2     = props.link2
  const link3     = props.link3
  const alink1    = props.alink1
  const alink2    = props.alink2
  const alink3    = props.alink3

  return (
    <>
    <div className='flex flex-row justify-between' onClick={()=>setMeunopen(!meunopen)}>
      <div className='p-1'>{main_name}
      {meunopen?
        <div className='flex flex-col space-y-10  w-64'>
          <div className='mt-10 hover:bg-gray-300 p-1'>      
            <a class="" href={alink1}>{link1}</a>
          </div>
          <div className='p-1 hover:bg-gray-300'>      
            <a class="" href={alink2}>{link2}</a>
          </div>
          <div className='p-1 hover:bg-gray-300'>      
          <a class="" href={alink3}>{link3}</a>
          </div>
        </div>:""}
      </div>
      {/* svg down icon */}
      {!meunopen?     
      <div className='mr-8'>
        <svg fill="#000000" height="30px" width="30px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" >
        <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
          c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
          s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/>
        </svg>      
      </div>:
      <div className='mr-8'>
      {/* svg up icon */}
        <svg
            width="30px"
            height="30px"
            viewBox="0 0 1024 1024"
            className="icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M903.232 768l56.768-50.432L512 256l-448 461.568 56.768 50.432L512 364.928z"
              fill="#000000"
            />
          </svg>   
      </div>}      
    </div>
    </>
  )
}

export default MobileDropdown