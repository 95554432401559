import React, { useState , useEffect } from 'react'
import ipad4 from './../images/podconn_tablet_dash.png'
import ipad5 from './../images/podconn_tablet_map.png'
import ipad6 from './../images/podconn_tablet_tree.png'
function Slider() {
    var slider_array = [ipad4,ipad5,ipad6]

    const max_count = slider_array.length - 1
    const [count , SetCount] = useState(0)
    useEffect(() => {
    const interval = setInterval(() => {
        IncreaseSlider();
    }, 5000);
        return () => clearInterval(interval);
    },[count]);
    const TestOnclick = (index) =>
    {
        SetCount(index)
    }  
    const IncreaseSlider = () => {
        SetCount(prev=>prev+1);
        if((count) >= (max_count))
        {
            SetCount(0)
        }
    }
    const DecreaseSlider = () => {
        SetCount(prev=>prev-1);
        if((count) <= (0))
        {
            SetCount(max_count)
        }
    }
    return (
    <>
        <div className='container mx-auto mt-20 '>
            <div className='mt-5 flex flex-col items-center justify-center space-y-5'>
                {/* <h1 className='text-blue-500 text-4xl p-2 mb-2 mt-2 text-bold'> <b>Data Visualization</b> Available On Tablet/Laptop/Mobile</h1> */}
                <div className='flex flex-row space-x-10'>
                    <div className='bg-white rounded-3xl'><img src={slider_array[count]} alt="ipad"></img> </div>
                </div>             
                {/* DecreaseSlider */}
                <div className='flex flex-row space-x-5'>
                    <button onClick={DecreaseSlider}>
                    <svg
                        fill="#000000"
                        width="40px"
                        height="40px"
                        viewBox="0 0 1920 1920"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                        <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        />
                        <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                            d="M629.228 331.011 0 960.239l629.228 629.228 155.901-155.901-363.071-363.071h1497.931V849.984H422.058l363.071-363.072z"
                            fillRule="evenodd"
                        />{" "}
                        </g>
                    </svg>
                    </button>    
                    {/* Dot? */}
                    {slider_array.map((slide,index) => (
                        <span  className={`cursor-pointer rounded-full bg-cover flex flex-col justify-center items-center  ${index === count?'bg-zinc-800':'bg-zinc-400'}`} key={index}
                            onClick={()=>TestOnclick(index)}
                        >
                            <div className=''>                           
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                {/* {index}     */}
                            </div>
                        </span>
                    ))}
                    <button onClick={IncreaseSlider}>
                        <svg fill="#000000" width="40px" height="40px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"/>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
                        <g id="SVGRepo_iconCarrier"> <path d="M1290.761 331.004 1134.86 486.905l363.071 363.071H0v220.511h1497.931l-363.071 363.071 155.901 155.902 629.228-629.228z" fillRule="evenodd"/> </g>
                        </svg>                        
                    </button>    
                </div>
            </div>
            <div>
            {/* {slider_array.map((slider_array,key) =>  {<li>key = {key}{slider_array}</li>}} */}
            </div>
        </div>        
    </>
  )
}

export default Slider