import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
        //main
        "category1"             : "Business Applications",
        /*"category1_pt1"         : "Tap Water Supply",
        "category1_pt2"         : "Sewage Treatment",
        "category1_pt3"         : "Direct Drinking Water",*/
        "category2"             : "Data Technology",
        /*"category2_pt1"         : "Data Analytics",
        "category2_pt2"         : "Device Management Models",
        "category2_pt3"         : "Data Communication Standards",*/
        "Contacts"              : "Contacts",

        //nav menu
        "About"                 : "About Us",    
        "Services"              : "Sevices",    
        "Partners"              : "Partners", 

        //Company
        "CompanyName"           : "Silver Dragon Metering",   
        "Companybackground"     : ["Silver Dragon Metering Limited is a member of the publicly listed ","China Water Affairs Group ( HKEX: 0855)",". We are focused on providing data acquisition and analytics for different business sectors within the Group by maintaining the common data platform as well as developing the  data communication standards.","http://www.chinawatergroup.com/eng/"],

        "technology"            : "Data Technology",    
        "Analytics"             : "Analytics",    
        "Solution_Overview"     : "Our Data Solutions",
        "overview_msg"          : "For the different business sectors within the Group, we provide smart data solutions such as high-performing remote reading solutions, advanced monitoring of pressure and leakages as well as intelligent data analytics. Our data technology is an intelligent enabler for improving consumer relations and optimizing operations. It is about quality management, revenue protection, asset management and non-revenue water reduction. ",    

        "pt_1"                  :"Improve data security and reliability",
        "pt_2"                  :"Improve efficiency",
        "pt_3"                  :"Leak detection and alerts",
        "pt_4"                  :"Customisable and automated reporting",
        "pt_5"                  :"Reduce non-revenue water",
        "pt_6"                  :"Event Management",

        "dashboard_title"       :"Sample Dashboards",
        "dashboar_word"         :"Advanced Water Analytics, Customer Engagement and Mobile App Solutions",

        "Data_Models"           : "Device Management Models",
        "Data_model_pt"         : "For the facilities within the Group, we have a high variety of devices and they are deployed in many different scenarios; therefore, we have been designing different device management models aimed for effective data acquisition and analytics.",
        "Data_Standard"         : "Data Communication Standards​",
        "Data_standard_pt"      : ["For business sectors within the Group and different deployments, we have been working on  the data communications standards based on our requirements with the standards from the different international bodies such as ","IEEE、OMA、３GPP、IETF、WI-SUN ."," ."],

        
        //Service_2
        "realtimedata"          : "Real Time Data Monitoring",
        "upefficiency"          : "Increase Efficiency",
        "cs_service"            : "Build Stronger Relationships with Your Customers",

        // image path
        "chinawaterbusiness"    : "http://www.chinawatergroup.com/eng/upfiles/image/202211/20221108183316911691.png",

        // business applications
        "tapwater" : "Tap Water Supply",
        "tapwaterdescriptions" : "The Group operates the tap water supply for over 60 cities with our water supply plants and other facilities. Currently we are capable of providing over 8 million tons of water supply daily for all our customers.",
        "sewagetreatment" : "Sewage Treatment",
        "sewagetreatmentdescriptions" : "Sewage treatment projects of the Group are well spread in various provincial cities and regions across China, including Beijing, Tianjin, Shenzhen, Guangdong, Henan, Hebei, Hubei, Jiangxi and Shaanxi, with a 25-year franchise granted by local governments.",
        "directdrinkingwater" : "Direct Drinking Water",
        "directdrinkingwaterdescriptions" : "The Group is operating 2,000+ direct drinking water projects in over 100 Chinese cities , including various types of business scenarios such as housing estates, schools, hospitals, institutions, and industrial parks.",
        "dataanalytics"                     : "Data Analytics",
        "devicemanagementmodels"            : "Management Models",
        "datacommunicationstandards"        : "Communication Standards",

        "hero_line1" : "Water is the arche",
        "hero_line2" : "Legal Compliance, Sustainable Development",
        "hero_line3" : "and Social Responsibility",

        // analytics icon captipons
        "customizable" : "Customizable",
        "efficiency" : "Efficiency",
        "faultdetection" : "Fault Detection",
        "security" : "Security",

 
        // device management icon captions
        "hierarchical":"Hierarchical",
        "mesh":"Mesh",
        "extensible":"Extensible",

        // analytics usages
        "assetmanagement_title" : "Improve Asset Management",
        "assetmanagement_description" : "With our smart data solutions, different business sectors within the Group can have a  clear understanding of where in their systems require maintenance and  can better position to repair and upgrade assets for a safer, sustainable and more efficient system.",

        "theft_leakage_detection_title" : "Active theft & leakage detection",
        "theft_leakage_detection_description" : "The process of automation is known to deter theft of service if people are aware of a potential tamper monitor feature. In addition, more frequent and accurate water leak information provided by our data platform helps relevant parties to pick up on any abnormalities sooner.",
        
        "non_revenue_water_title" : "Reduce Non-Revenue Water",
        "non_revenue_water_description" : "Reducing and locating non-revenue water, water loss becomes much easier when you have access to the right information at the right time.",
        
        "custom_reporting_title" : "Customisable and Automated Reporting",
        "custom_reporting_description" : "Efficient metering, precise calculation of water consumption and accurate billing for residential, commercial and industrial consumers.",
               
        "address" : "B808-809 8/F Blk. B, Sea View Estate, 2-8 Watson Road, North Point, Hong Kong",
        "tel" : "Tel",
        "email" : "E-Mail",
        "fax" : "Fax",

        "partners" : "Partners",
    }
  },
  zh: {
    translation: {
        //main
        "category1"             : "應用業務",
        /*"category1_pt1"         : "自來水供應",
        "category1_pt2"         : "污水處理",
        "category1_pt3"         : "直飲水",*/
        "category2"             : "計量技術",
        /*"category2_pt1"         : "數據分析",
        "category2_pt2"         : "設備管理模型",
        "category2_pt3"         : "數據傳輸標準",*/
        "Contacts"              : "聯繫我們",

        //nav menu
        "About"                 : "公司介紹",             
        "Services"              : "服務",    
        "Partners"              : "合作夥伴",    
        //Company
        "CompanyName"           : "銀龍計量科技",   
        "Companybackground"     : ["銀龍計量科技為","中國水務集團（港交所：0855）","旗下的成員，專注於針對集團內不同業務，作出整體的數據採集及分析。我們的工作包括建立數據平台，制定數據傳輸標準等。","http://www.chinawatergroup.com/"],

        "technology"            : "計量技術",    
        "Solution_Overview"     : "智能計量解決方案",    
        "overview_msg"          : "我們為集團內不同業務提供不同解決方案，如遠程抄表、壓力/洩漏監控及智能數據分析等，這有效地改善消費者關係和優化運營，其中涉及質量管理、收入保護、資產管理和減低無收益水等。",    

        "pt_1"                  : "提高數據安全性和可靠性",
        "pt_2"                  : "提高效率",
        "pt_3"                  : "洩漏檢測和警報",
        "pt_4"                  : "可定制/自動化報告",
        "pt_5"                  : "減少無收益水量",
        "pt_6"                  : "事件管理",

        "dashboard_title"       : "儀表板概覽",
        "dashboar_word"         : "提供計量分析、客戶參與和應用程序解決方案",

        "Data_Models"           : "設備管理模型​",
        "Data_model_pt"         : "因為有着各種不同的設備，我們也開發了不同的設備管理模型​，以便於有效的數據採集和分析。",
        "Data_Standard"         : "數據傳輸標準",
        "Data_standard_pt"      : ["因應集團內業務的各種場景及設備，我們會根據相應的國際標準，如 ","IEEE、OMA、３GPP、IETF、WI-SUN"," 等，為不同業務制定數據傳輸標準。"],

        //Service_2
        "realtimedata"          : "實時數據監察",
        "upefficiency"          : "提升效率",
        "cs_service"            : "與客戶建立更牢固的關係",
        
        // image path
        "chinawaterbusiness"    : "http://www.chinawatergroup.com/upfiles/image/202211/20221108183176357635.png",
         
        // business applications
         "tapwater" : "自來水供應",
         "tapwaterdescriptions" : "集團營運超過60個城市的供水項目, 所管理的厰房及設備，至今已形成每天超過800萬噸的綜合處理能力。",
         "sewagetreatment" : "污水處理",
         "sewagetreatmentdescriptions" : "集團所經營之污水處理項目遍佈於中國多個省市及地區，包括北京市、天津市、深圳市、廣東省、河南省、河北省、湖北省、江西省及陝西省，並獲當地政府授予25年特許經營權進行運營。",
         "directdrinkingwater" : "直飲水",
         "directdrinkingwaterdescriptions" : "集團已經在100多個城市投資運營了2000多個直飲水項目，包括居民小區、 學校、醫院、機關、園區等。",
         "dataanalytics"                      : "數據分析",
         "devicemanagementmodels"             : "設備管理模型",
         "datacommunicationstandards"         : "數據傳輸標準",

         "hero_line1" : "以水為本",
         "hero_line2" : "守法經營，持續發展，達善社會",
         "hero_line3" : "",

          // analytics icon captipons
        "customizable" : "因事制宜",
        "efficiency" : "提高效率",
        "faultdetection" : "洩漏檢測",
        "security" : "提高安全",

         // device management icon captions
         "hierarchical":"分級網絡",
         "mesh":"網狀網路",
         "extensible":"可擴展性",

         // analytics usages
        "assetmanagement_title" : "資產管理",
        "assetmanagement_description" : "我們的智能數據方案，可幫助集團內的不同業務，尋找出其系統中需要維護或升級的部份，這樣可以對集團內各業務系統有着全盤理解，使這些系統更安全、更可靠和更有效率。",

        "theft_leakage_detection_title" : "篡改及洩漏偵測",
        "theft_leakage_detection_description" : "因數據可以集中於集團平台，我們可以用自動演算法，去檢測設備的數據篡改。同樣地，也可以偵測不同供水設備的洩漏狀況。",
        
        "non_revenue_water_title" : "減低非收入用水量",
        "non_revenue_water_description" : "因着集團平台上的適時有效數據，不同業務可以檢查出損失水量以至減低非收入用水。",
        
        "custom_reporting_title" : "自動化特製報表",
        "custom_reporting_description" : "集團內不同的業務，有着不同的用戶群，如住宅、商業或工業用戶，我們的數據平台，可以因不同的用戶群生成不同種類的自動報表。",

        "address" : "香港北角屈臣道２－８號海景大厦B座B８０８－８０９室",
        "tel" : "電适",
        "email" : "電郵",
        "fax" : "傳真",

        "partners" : "策略夥伴",
        
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    supportedLngs: ["en","zh","cn"],
    lng: "zh", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;