import React from 'react'
import Slider from './Slider';
import { useTranslation } from 'react-i18next';

function Ipad_slide() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className='container mx-auto max-w-full mt-10'>
        <div className='bg-slate-100 p-10 flex justify-center'>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>
           {/* left grid */}
            <div className='text-center mt-1 md:mt-20'>
              <div className='text-3xl lg:text-5xl p-2'>{t('dashboard_title')}</div>
            </div>
           {/* right grid */}
            <div className=''>
              <Slider />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Ipad_slide