function Dropdown(props) {
    const main_name = props.main
    const link1     = props.link1
    const link2     = props.link2
    const link3     = props.link3
    const alink1    = props.alink1
    const alink2    = props.alink2
    const alink3    = props.alink3

    return (   
        <>        
            <div className='z-1 relative'>
                <button className="peer px-5 py-2  text-black">{main_name}</button>        
                <div className="absolute hidden peer-hover:flex hover:flex flex-col bg-white drop-shadow-lg">
                    <a className="px-5 py-3 text-lg hover:text-gray-300" href={alink1}>{link1}</a>
                    <a className="px-5 py-3 text-lg hover:text-gray-300" href={alink2}>{link2}</a>
                    <a className="px-5 py-3 text-lg hover:text-gray-300" href={alink3}>{link3}</a>
                </div>
            </div>
        </>
    )
}

export default Dropdown