import './App.css';
import About from './components/About';
import Analytics from './components/Analytics';
import Business_App from './components/Business_App';
import Datamodeling from './components/Datamodeling';
import Datastandard from './components/Datastandard';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { useTranslation } from 'react-i18next';
import { Title } from './components/Title';
import Herosection from './components/Herosection';
import Partners from './components/Partners';

function App() {
  const { t, i18n } = useTranslation();
  return (
    <>
    <div className="App">
      <div className='hidden'>
      <Title newTitle={t('CompanyName')}/>
      </div>
      <div id="">
        <Navbar />
      </div>
      <div id="hero">
        <Herosection />
      </div>
      <div id="about">
        <About />        
      </div>
      <div id="Business_Application">
        <Business_App />
      </div>
      <div id="Analytics">
        <Analytics />
      </div>
      <div id="Datamodeling">
        <Datamodeling />
      </div>
      <div id="Datastandard">
        <Datastandard />
      </div>
      <div id="Partners">
        <Partners />
      </div>

      <div id ='footer' className='sticky top-[100vh]'> 
        <Footer />
      </div>
    </div>
    </>
  );
}

export default App;
