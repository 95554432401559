import React from 'react'
import hero_banner from './../images/waterbanner2.jpg'
import { useTranslation } from 'react-i18next';

function Herosection() {
    const { t, i18n } = useTranslation();
    return (
      <>
       <div className='container mx-auto max-w-full'>
          <div className='relative'>
          <img className="inset-0  object-cover w-full opacity-75"  
                  src={hero_banner} alt="herobanner" />
          <div className='absolute top-1/2 left-40 md:left-30 lg:left:60 -translate-x-1/4 -translate-y-1/2  opacity-75 bg-gray-200 rounded-xl p-5'>
              <h2 className="text-xl md:text-3xl lg:text-6xl text-gray-800 font-semibold">{t('hero_line1')}</h2>                      
              <p className='pt-2.5 text-base md:text-2xl lg:text-3xl text-black font-light'>{t('hero_line2')}
              <br></br>
              {t('hero_line3')}</p>
          </div>
          </div>
      </div>
      </>
    )
  }
  
export default Herosection