import './../index.css';
import React from 'react'
import { useTranslation } from 'react-i18next';
import Ipad_slide from './Ipad_slide';
import report from './../logo/podconn_report_150150.png';
import efficiency from './../logo/podconn_efficiency_150150.png'
import efficiency_1 from './../logo/podconn_improve_efficiency.png'
import leak from './../logo/podconn_leak_detection_150150.png'
import secure from './../logo/podconn_security_link_150150.png'
import secure_1 from './../logo/podconn_secure.png'
import cost from './../logo/podconn_save_cost.png'
import quickresponse from './../logo/podconn_quick_response.png'

function Analytics() {
    const { t, i18n } = useTranslation();
    return (
    <>    

    <div className='container mx-auto mt-20'>
        <div className='flex flex-col p-5'>                
            <h1 className='p-1 text-3xl lg:text-5xl font-sans text-center'>{t('technology')}</h1>
            <div className='mt-2 flex flex-col-reverse md:flex-row mb-5 md:space-x-10 bg-slate-100 p-3'>
                {/* left side */}
                <div className='p-1 md:w-3/5 mt-5'>
                    <h2 className='p-2 text-2xl lg:text-4xl text-semibold mb-5'>
                       {t('Solution_Overview')}
                    </h2>
                    <p className='p-2 text-lg lg:text-1xl font-light'>
                        {t('overview_msg')}
                    </p>
                </div>
                {/* Right side */}
                <div className='flex items-center justify-center p-5'>
                    <img src={require(`./../images/podconn_workflow_${i18n.language}_100.png`)} alt="wokflow"></img> 
                </div>
            </div>    
            {/* ana */}
            <div className='text-center mt-20'>
                <h3 className='text-4xl lg:text-5xl p-2 mb-5'>{t('dataanalytics')}</h3>
                {/* <p className='p-2 text-lg md:text-xl font-light mb-10'>因應不同的應用業務，我們會在集團的數據平台上，開發不同的分式模型。
                </p> */}
            </div>

            {/* icon and word */}
            <div className='grid grid-cols-2 lg:grid-cols-4 gap-2'>
                <div>
                    <div className='flex flex-col justify-center bg-gray-100 border-b-8 border-blue-600 hover:border-purple-500 p-10'>                       
                            <img className='item-center mt-2 p-10' src={report} alt="report"/>
                            <div className='text-center mt-5 text-xl font-light'>{t('customizable')}</div>
                    </div>
                </div>
                <div>
                    <div className='flex flex-col justify-center bg-gray-100 border-b-8 border-blue-600 hover:border-purple-500 p-10'>
                        <img className='item-center mt-2 p-10' src={efficiency} alt="efficiency"/>
                        <div className='text-center mt-5 text-xl font-light'>{t('efficiency')}</div>
                    </div>
                </div>
                <div>
                    <div className='flex flex-col justify-center bg-gray-100 border-b-8 border-blue-600 hover:border-purple-500 p-10'>
                        <img className='item-center mt-2 p-10' src={leak} alt="report"/>
                        <div className='text-center mt-5 text-xl font-light'>{t('faultdetection')}</div>
                    </div> 

                </div>
                <div>
                    <div className='flex flex-col justify-center bg-gray-100 border-b-8 border-blue-600 hover:border-purple-500 p-10'>
                        <img className='item-center mt-2 p-10' src={secure} alt="efficiency"/>
                        <div className='text-center mt-5 text-xl font-light'>{t('security')}</div>
                    </div>
                </div>

            </div>                

          
            {/* END OF POINT */}
            <div className='mt-20'>
            {/* START OF PIC AND WORDING */}
            {/* ROW 1 */}
            <div className="flex space-y-1 md:space-y-10 lg:space-y-0 flex-col md:flex-row mx-0 md:mx-10 space-x-0 md:space-x-40" >
                <div className="flex justify-center ">
                    <div className=''>               
                        <img className='scale-75 max-w-xs self-center' src={secure_1} alt="secure1"/>  
                    </div>
                </div>
                <div className="flex flex-col  max-w-2xl mx-auto">               
                    <h2 className='text-blue-400 font-bold text-3xl md:text-4xl text-center'> 
                            {t('assetmanagement_title')}
                    </h2>
                    <p className='text-black font-light text-lg text-center md:text-left md:text-2xl mt-5'>
                            {t('assetmanagement_description')}
                    </p>
                </div>                
            </div>                
            {/* ROW 2 */}
            <div className='mt-0 md:mt-20'>
                <div className="flex space-y-1 md:space-y-10 lg:space-y-0 flex-col-reverse md:flex-row space-x-0 md:space-x-0">
                {/* <div className="flex md:flex-col flex-col-reverse"> */}
                    <div className="flex flex-col max-w-2xl mx-auto">               
                        <h2 className='text-blue-400 font-bold text-3xl md:text-4xl text-center md:mt-10 mt-10 lg:mt-0 '> 
                            {t('theft_leakage_detection_title')}
                        </h2>
                        <p className='text-black font-light text-lg md:text-2xl text-center md:text-left mt-5'>
                            {t('theft_leakage_detection_description')}
                        </p>
                    </div>                

                    <div className="flex justify-center ">               
                         <img className='scale-75 max-w-xs self-center' src={quickresponse} alt='eff1'/>
                    </div>
                </div>                
            </div>                
            {/* ROW 3 */}
            <div className='mt-0 md:mt-20'>
                <div className="flex space-y-1 md:space-y-10 lg:space-y-0 flex-col md:flex-row mx-0 md:mx-10 space-x-0 md:space-x-40">
                    <div className="flex justify-center">
                        <div className=''>               
                            <img className='scale-75 max-w-xs self-center' src={cost} alt='cost'/>                    
                        </div>
                    </div>
                    <div className="flex flex-col max-w-2xl mx-auto">               
                        <h2 className='text-blue-400 font-bold text-3xl md:text-4xl text-center'> 
                            {t('non_revenue_water_title')}
                        </h2>
                        <p className='text-black font-light text-lg md:text-2xl text-center md:text-left mt-5'>
                            {t('non_revenue_water_description')}                       
                        </p>
                    </div>                
                </div>                
            </div>                
            {/* ROW 4 */}
            <div className='mt-0 md:mt-20 '>
                <div className="flex space-y-1 md:space-y-10 lg:space-y-0 flex-col-reverse md:flex-row mx-0 md:mx-10 space-x-0 md:space-x-0">
                    <div className="flex flex-col max-w-2xl mx-auto">               
                        <h2 className='text-blue-400 font-bold text-3xl md:text-4xl text-center md:mt-10 sm:mt-10 lg:mt-0'> 
                            {t('custom_reporting_title')}
                        </h2>
                        <p className='text-black font-light text-lg md:text-2xl text-center md:text-left mt-5'>
                            {t('custom_reporting_description')}                                
                        </p>
                    </div>                
                        <div className="flex items-center justify-center">               
                            <div className=''>
                                <img className='scale-75 max-w-xs self-center' src={efficiency_1} alt='response'/>
                            </div>
                        </div>
                    </div>                
                </div>                
            </div>
            < Ipad_slide />
        </div> {/*flex flex-col */}
    </div>
    </>
  )
}

export default Analytics