import './../index.css';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import LangDropdown from './LangDropdown';
import Dropdown from './Dropdown';
import MobileDropdown from './MobileDropdown';
import logo from "./../logo/sdmetering_with_logo_500x65.png"
function Navbar() {
    const  [ishamopen,setIsHamOpen] = useState(false);
    const { t, i18n } = useTranslation();
    return (
        <>
            <div>
                <div className='z-1 p-5'>
                    <div className='flex flex-row justify-between'>
                        {/* LOGO */}
                        <div>
                            <img className='mt-5 ml-0' src={logo} alt=""></img>
                        </div>                      
                        {/* HAM */}
                        <div className='flex flex-row text-2xl cursor-pointer md:hidden'>
                            <div className='p-5 mt-5'>
                                {/* <LangDropdown /> */}
                            </div>

                            <button onClick={()=>setIsHamOpen(!ishamopen)}>
                                <svg
                                    width="50px"
                                    height="50px"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M3 6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H4C3.44772 7 3 6.55228 3 6ZM3 12C3 11.4477 3.44772 11 4 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H4C3.44772 13 3 12.5523 3 12ZM3 18C3 17.4477 3.44772 17 4 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H4C3.44772 19 3 18.5523 3 18Z"
                                    fill="#0F1729"
                                    />
                                </svg>
                            </button>                            
                        </div>
                        <div className='relative z-10 mt-8 mr-3 md:text-1xl lg:text-2xl font-medium hidden md:block'> 
                            <div className=' flex lg:flex-row lg:space-x-10'>
                            <div className=''> 
                                <Dropdown main={t('category1')} link1={t('tapwater')} link2={t('sewagetreatment')} link3={t('directdrinkingwater')} alink1={"#Tapwater"} alink2={"#Sewage"} alink3={"#Drinking"} /> 
                            </div>
                            <div> 
                                <Dropdown main={t('category2')} link1={t('dataanalytics')} link2={t('devicemanagementmodels')} link3={t('datacommunicationstandards')} alink1={"#Analtyic"} alink2={"#Modeling"} alink3={"#Datastandard"}/> 
                            </div>
                                <div className='bg-white'>  
                                    <LangDropdown />
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
                {/* This should be hide when menu larger than ? */}
                {ishamopen?
                <div className='mt-5 pl-5 flex flex-col gap-10 text-2xl font-medium md:hidden'>
                    <div className=''>                    
                        <MobileDropdown main={t('category1')} link1={t('tapwater')} link2={t('sewagetreatment')} link3={t('directdrinkingwater')} alink1={"#Tapwater"} alink2={"#Sewage"} alink3={"#Drinking"}/>
                    </div>
                    <div className=''>                    
                        <MobileDropdown main={t('category2')} link1={t('dataanalytics')} link2={t('devicemanagementmodels')} link3={t('datacommunicationstandards')} alink1={"#Analtyic"} alink2={"#Modeling"} alink3={"#Datastandard"}/>
                    </div>
                    <div className='bg-white'>  
                        <LangDropdown />
                    </div> 
                </div>
                :
                <div>
                </div>
                }
            </div> 
        </>
    )
}

export default Navbar