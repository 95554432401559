import './../index.css';
import React from 'react'
import logo from "./../logo/sdmetering_logo_white_120.png"

import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();
  return (
    <>
    <div id='' className='max-w-full container'>
        <div className='flex flex-col lg:flex-row item-center bg-black text-white lg:space-x-40 p-2 pb-5 space-x-5'>
              <div className='mt-10 flex-initial w-2/6 md:ml-5'>
                <img className=' p-2 rounded-lg ' src={logo} alt=""></img>
                <div className='mt-2 text-light'>© 2023 All Rights Reserved.</div>
              </div>
              <div className='mt-5'>
                  <h2 className='mb-5 text-base  text-blue-500  md:text-2xl font-bold uppercase'>
                    {t('category1')}
                  </h2>                    
                  <div className='mt-5 flex flex-col space-y-5 '>
                    <div className='text-sm md:text-base font-light'>
                      <a href="#Tapwater">
                        - {t('tapwater')}
                      </a>                      
                    </div>
                    <div className='text-sm md:text-base font-light'>
                      <a href="#Sewage">
                        - {t('sewagetreatment')}
                      </a>                      
                    </div>
                    <div className='text-sm md:text-base font-light'>
                      <a href="#Drinking">
                        - {t('directdrinkingwater')}
                      </a>                      
                    </div>
                  </div>
              </div>              
              <div className='mt-5'>
                  <h2 className='mb-5 text-base text-blue-500  md:text-2xl text-light uppercase font-bold'>
                    {t('category2')}
                  </h2>                    
                  <div className='mt-5 flex flex-col space-y-5 text-light'>
                    <div className='text-sm md:text-base font-light'>
                      <a href="#Analtyic">
                        - {t('dataanalytics')}
                      </a>                      
                    </div>
                    <div className='text-sm md:text-base font-light'>
                      <a href="#Modeling">
                        - {t('devicemanagementmodels')}
                      </a>                      
                    </div>
                    <div className='text-sm md:text-base font-light'>
                      <a href="#Datastandard">
                        - {t('datacommunicationstandards')}
                      </a>                      
                    </div>
                  </div>
              </div>
              <div className='mt-5'>
                <h2 className='text-base text-blue-500 md:text-2xl uppercase font-bold'>
                    {t('Contacts')}
                </h2>                    
                <div className='mt-5 flex flex-col space-y-5 text-sm md:text-base font-light'>
                    <a href="mailto:abc@gmail.com">{t('email')} : info@sdmetering.com</a>
                    <div>{t('tel')} : (852)21173800</div>
                    <div>{t('fax')} : (852)25108742</div>
                    <div>{t('address')}</div>
                </div>
              </div>

             
      </div>
    </div>
    </>
  )
}

export default Footer