import React from 'react'
import { useTranslation } from 'react-i18next';
import './../index.css';
import china_m from "./../logo/chinamobile_120.png" 
import china_t from "./../logo/chinatelecom_120.png" 
import cyberport from "./../logo/cyberport_120.png" 
import scc from "./../logo/scc_120.png" 
import podconn from "./../logo/podconn_logo_120.png"
import china_w from "./../logo/chinawater_120.png"

function Partners() {
  const { t, i18n } = useTranslation();
  return (
    <>
        <div className='container max-w-full flex justify-center bg-slate-200 mt-5 p-10'>
            <div className='flex flex-col justify-center items-center'>
                <h2 className='text-4xl lg:text-5xl font-semibold text-center text-black mt-5 mb-10'>{t('partners')}</h2>
                  <div className='grid grid-col-2 lg:grid-cols-2 xl:grid-cols-6 gap-10 p-5 md:p-10'>
                    <div className='justify-self-center'><img className='ml-0 md:ml-10' src={scc} alt='scc'></img></div>                
                    <div className='justify-self-center'><img className='' src={china_m} alt='chinamobile'></img></div>                
                    <div className='justify-self-center'> <img className='' src={china_t} alt='chinat'></img></div>                
                    <div className='justify-self-center'><img className='' src={cyberport} alt='cyberport'></img></div>                
                    <div className='justify-self-center'><img className='' src={china_w} alt='china_w'></img></div>                
                    <div className='justify-self-center'><img className='' src={podconn} alt='podconn'></img></div>                
                </div>
            </div>
        </div>
    </>
  )
}

export default Partners