import React from 'react'
import datamodel from './../images/datamodel.png';
import { useTranslation } from 'react-i18next';
import hierarchy from './../logo/podconn_hierarchy_150150.png'
import mesh from './../logo/podconn_mesh_network_150150.png'
import scalable from './../logo/podconn_scalable_150150.png'
function Datamodeling() {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className='container mx-auto max-w-7xl mt-20'>
        <div className='text-center mt-5'>
          <h3 className='text-3xl lg:text-5xl p-2'>
            {t("Data_Models")}
          </h3>
          <p className='mt-2 md:mt-5 p-2 text-base md:text-xl font-light'>
            {t('Data_model_pt')}
          </p>

          <div className='container w-2/3 mx-auto mt-3 md:mt-10 mb-10'>
            <div className='grid grid-col-1 md:grid-cols-3 gap-2'>
              <div className='p-2 flex flex-col justify-center items-center bg-gray-100 border-b-8 border-blue-600 hover:border-purple-500'>
                <div>
                  <img className='py-10' src={hierarchy} alt='hierarchy'/>
                </div>          
                <div className='pb-5 text-2xl'>{t('hierarchical')}</div>
              </div>          
              <div className='p-2 flex flex-col justify-center items-center bg-gray-100 border-b-8 border-blue-600 hover:border-purple-500'>
                <div>
                  <img className='py-10' src={mesh} alt='mesh'/>
                </div>          
                <div className='pb-5 text-2xl'>{t('mesh')}</div>
              </div>          
              <div className='p-2 flex flex-col justify-center items-center bg-gray-100 border-b-8 border-blue-600 hover:border-purple-500'>
                <div>
                  <img className='py-10' src={scalable} alt='scalable'/>
                </div>          
                <div className='pb-5 text-2xl'>{t('extensible')}</div>
              </div>
            </div>
          </div>
        </div>
        <img className='p-2 px-10' src={datamodel} alt='datamodeling'></img>        
      </div>
    </>
  )
}

export default Datamodeling