import './../index.css';
import React from 'react'
import { useTranslation } from 'react-i18next';

function About() {
    const { t, i18n } = useTranslation();
    return (

    <>
    {/* About Section */}
        <div className='container mx-auto mt-5'>
    {/* Text Section */}
        <h2 id='About'  className='pt-20 p-1 text-3xl lg:text-4xl font-semibold text-center tracking-wider'>
            {t('CompanyName')}
        </h2>
        <div className='max-w-6xl mx-auto'>
        <div className='text-xl lg:text-2xl font-light  p-5 '>
            {t('Companybackground.0')}<a className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' href={t('Companybackground.3')} target="_blank" rel="noopener noreferrer">{t('Companybackground.1')}</a>{t('Companybackground.2')}
            </div>
        </div>
    {/* Image Section */}
        <div className='mt-10 flex justify-center justify-self-center'>
            {/* <img className='mt-2 max-w-full xl:max-w-6xl rounded-3xl' src={t('chinawaterbusiness')} alt="image_855"/> */}
            <img className='mt-2 max-w-full xl:max-w-6xl rounded-3xl'src={require(`./../images/about_${i18n.language}.png`)} alt="image_855"></img> 

        </div>
    </div>    
</>
  )
}

export default About