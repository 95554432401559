import './../index.css';
import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';

function LangDropdown() {
    const  [isOpen,setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
 
    return (
    <div className='relative z-10'>
        <button className=' w-24 flex flex-col' onClick={()=>setIsOpen(!isOpen)}>
            <svg
                width="60px"
                height="40px"
                viewBox="-2 0 15 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
            <path
            d="M5.5 16.5H19.5M5.5 8.5H19.5M4.5 12.5H20.5M12.5 20.5C12.5 20.5 8 18.5 8 12.5C8 6.5 12.5 4.5 12.5 4.5M12.5 4.5C12.5 4.5 17 6.5 17 12.5C17 18.5 12.5 20.5 12.5 20.5M12.5 4.5V20.5M20.5 12.5C20.5 16.9183 16.9183 20.5 12.5 20.5C8.08172 20.5 4.5 16.9183 4.5 12.5C4.5 8.08172 8.08172 4.5 12.5 4.5C16.9183 4.5 20.5 8.08172 20.5 12.5Z"
            stroke="#121923"
            strokeWidth="1.2"
            />
            </svg>
            {isOpen && 
                <div className='absolute mt-4 md:mt-10 w-24'>
                    <ul className='flex flex-col space-y-5 mt-5 md:mt-2 bg-white'>
                        <li className="hover:text-gray-300 p-3 mr-3" onClick={() =>changeLanguage("en")}> En</li>
                        <li className="hover:text-gray-300 p-3 mr-3" onClick={() => changeLanguage("zh")}> 中文 </li>
                        {/* <li className="hover:text-gray-300 p-3 mr-3" onClick={() => changeLanguage("zh")}> 簡體 </li> */}
                    </ul>
                </div>
            }
        </button>
    </div>
    )
}

export default LangDropdown