import React from 'react'
import { useTranslation } from 'react-i18next';
import datastandard from './../images/datastandard.png';

function Datastandard() {
    const { t, i18n } = useTranslation();
    return (
        <>
        <div className='container mx-auto max-w-7xl mt-20 mb-20'>
            <div className='p-2 text-center'>
            <h3 className='text-3xl lg:text-5xl p-2 mt-2'>
                {t("Data_Standard")}
            </h3>
            <p className='p-2 text-base md:text-xl font-light'>
                {t('Data_standard_pt.0')}
                <a className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' href="https://standards.ieee.org/" target="_blank" rel="noreferrer" >IEEE</a>&nbsp;,
                <a className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' href="http://technical.openmobilealliance.org/" target="_blank" rel="noreferrer">OMA</a>&nbsp;,
                <a className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' href="https://www.3gpp.org/specifications" target="_blank" rel="noreferrer">3GPP</a>&nbsp;,
                <a className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' href="https://www.ietf.org/standards/" target="_blank" rel="noreferrer" >IETF</a>&nbsp;,
                <a className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' href="https://wi-sun.org/" target="_blank" rel="noreferrer" >WI-SUN</a>
                {t('Data_standard_pt.2')}
            </p>
            </div>
            <img className='p-2 px-10' src={datastandard} alt='datastandard'></img>        
        </div>
        </>
    )
}

export default Datastandard