import React from 'react'
import dashboard from './../images/dashboard.jpg';
import { useTranslation } from 'react-i18next';
import device_management_model from './../images/device_management_model.jpg' 
import tapwater_supply from './../images/water_supply_4.jpg'
import sewage6 from './../images/sewage_treatment_6.jpg'
import sewage5 from './../images/sewage_treatment_5.jpg'
import watersupply2 from './../images/water_supply_2.jpg'
import watersupply3 from './../images/water_supply_3.jpg'
import ro3 from './../images/direct_drinking_1.jpg'
import ro2 from './../images/direct_drinking_4.jpg'
import drink6 from './../images/direct_drinking_6.jpg'
import sewage4 from './../images/sewage_treatment_4.jpg'
import rfstandard from './../images/rfstandard.jpg'
function Business_App() {
  const { t, i18n } = useTranslation();
  return (
    <>
    <div className='z-10 container mx-auto mb-10'>
      <div className='flex flex-col'>
        {/* grid */}
        <div className='grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-2 p-2 '>
        <div className='group bg-blue-100 flex justify-center items-center hover:text-white text-xl hover:text-2xl md:text-1xl md:hover:text-2xl xl:text-2xl xl:hover:text-3xl cursor-pointer'>
              <div className='relative opacity-25 group-hover:opacity-75'> 
                  <img className='aspect-[395/222]' src={tapwater_supply} alt='tapwater_supply'></img> 
                  {/* <img className='' src="http://www.chinawatergroup.com/upfiles/image/202203/20220307101937503750.jpg" alt="tapwater"></img> */}
              </div>
              <div className='absolute font-semibold p-10'>
                <a className='group' href="#Tapwater">
                {t('tapwater')}
                </a>
              </div>
            </div>
            <div className='group bg-blue-100 flex justify-center items-center hover:text-white text-xl hover:text-2xl md:text-1xl md:hover:text-2xl xl:text-2xl xl:hover:text-3xl cursor-pointer'>
              <div className='relative opacity-25 group-hover:opacity-75' >
                  {/* <img src='https://cdn.hk01.com/di/media/images/dw/20220919/648609409613697024025169.jpeg/9LdE0V9kcy5T8OBIEtHCfsFs2wrscp5mz2p3a89qd2s?v=w1440r16_9' alt="sewagetreatment"></img> */}
                  <img className='opacity-25 hover:opacity-50 aspect-[395/222]' src={sewage4} alt="sewagetreatment"></img>

                  
              </div>
              <div className='absolute font-semibold p-10'>
                <a class="" href="#Sewage"> 
                {t('sewagetreatment')}
                </a>
              </div>
            </div>
            <div className='group bg-blue-100 flex justify-center items-center hover:text-white text-xl hover:text-2xl md:text-1xl md:hover:text-2xl xl:text-2xl xl:hover:text-3xl cursor-pointer'>
              <div className='relative opacity-25 group-hover:opacity-75'>
                {/* <img src='https://hanghaoguanjian.com/images/hanghaoguanjian.com-30b3d017dd-hanghaoguanjian.com.jpg?key=aGFuZ2hhb2d1YW5qaWFuLmNvbTovc3RhdGljL2ltYWdlcy9mOWU1YWQ3MTA4MmI5NGM3YzdmMzI4ZmRmNWQ3OGU3NV85MmM2N2I1MWMyZWMwMzA1ZmU4YzU1ZjA1M2ZjODc3OC5qcGc%3D'></img> */}
                  {/* <img className='opacity-25 hover:opacity-50 aspect-[395/222]' src='http://www.hbriver.com/d/file/cpfl/aszbtfl/2017-02-08/9115b919a7957362c4f101012cb8d18f.jpg' alt="directdrinkingwater"></img> */}
                  <img className='opacity-25 hover:opacity-50 aspect-[395/222]' src={drink6} alt="directdrinkingwater"></img>

                  
              </div>
              <div className='absolute font-semibold p-10'>
                <a class="" href="#Drinking"> 
                  {t('directdrinkingwater')}
                </a>
              </div>
            </div>
            <div className='group bg-blue-100 flex justify-center items-center hover:text-white text-xl hover:text-2xl md:text-1xl md:hover:text-2xl xl:text-2xl xl:hover:text-3xl cursor-pointer'>
              <div className='relative opacity-25 group-hover:opacity-75'>
                  <img className='aspect-[395/222]' src={dashboard} alt="dashboard"></img>
              </div>
              <div className='absolute font-semibold p-10'>
                <a class="" href="#Analytics"> 
                  {t('dataanalytics')}
                </a>
              </div>
            </div>
            <div className='group bg-blue-100 flex justify-center items-center hover:text-white text-xl hover:text-2xl md:text-1xl md:hover:text-2xl xl:text-2xl xl:hover:text-3xl cursor-pointer'>
              <div className='relative opacity-25 group-hover:opacity-75'>
                  {/* <img className='aspect-[395/222]' src='https://powerbi.microsoft.com/pictures/pages/what-is-data-modeling/what-is-data-modeling-hero.jpg' alt="devicemanagementmodels"></img> */}
                  <img className='aspect-[395/222]' src={device_management_model} alt='devicedatamodel'/>
              </div>
              <div className='absolute font-semibold p-10'>
                <a class="" href="#Datamodeling"> 
                  {t('devicemanagementmodels')}
                </a>
              </div>
            </div>
            <div className='group bg-blue-100 flex justify-center items-center hover:text-white text-xl hover:text-2xl md:text-1xl md:hover:text-2xl xl:text-2xl xl:hover:text-3xl cursor-pointer'>
              <div className='relative opacity-25 group-hover:opacity-75'>
                  <img className='aspect-[395/222]' src={rfstandard} alt="datacommunicationstandards" ></img>
              </div>
              <div className='absolute font-semibold p-10'>
                <a class="" href="#Datastandard"> 
                  {t('datacommunicationstandards')}
                </a>
              </div>
            </div>
        </div>        {/* END OF GRID */}
        <div id='Tapwater' className='mt-20 flex flex-col items-center'>
          <h3 className='p-2 text-3xl lg:text-5xl'>
            {t('tapwater')}
          </h3>
          <p className='p-2 text-base md:text-xl font-semibold max-w-3xl mx-auto'>
            {t('tapwaterdescriptions')}
          </p>
          <div className='grid grid-cols-2 gap-2'>
            {/* <img src="http://www.zkzls.com/UploadFiles/2017-09/15047525374226652.JPG" alt="waterimg"></img>
            <img className='' src={watersuppl2} alt="watersupply"></img> */}
            <img className='' src={watersupply3} alt="watersupply3"></img>
            <img className='' src={watersupply2} alt="watersupply2"></img>
          </div>
        </div>

        <div id='Sewage' className='mt-20 flex flex-col items-center'>
          <h3 className='p-2 text-3xl lg:text-5xl'>
            {t('sewagetreatment')}
          </h3>
          <p className='p-2 text-base md:text-xl font-light max-w-3xl mx-auto'>
            {t('sewagetreatmentdescriptions')}          
          </p>
          <div className='grid grid-cols-2 gap-2'>
            <img className='' src={sewage5} alt="sewagewater5"></img>
            <img className='' src={sewage6} alt="sewagewater6"></img>
          </div>
        </div>

        <div id='Drinking' className='mt-20 flex flex-col items-center'>
          <h3 className='p-2 text-3xl lg:text-5xl'>
            {t('directdrinkingwater')}
          </h3>
          <p className='p-2 text-base md:text-xl font-light max-w-3xl mx-auto'>
            {t('directdrinkingwaterdescriptions')}
          </p>
          <div className='grid grid-cols-2 gap-2'>
            <img className='' src={ro3} alt="ro3"></img>
            <img className='' src={ro2} alt="ro2"></img>
          </div>
        </div>        
      </div>  {/* END OF FLEX */}
    </div>
    </>
  )
}

export default Business_App